<template>
  <div>
    <hero-alt
      img="https://image.freepik.com/photos-gratuite/ouvrir-voler-vieux-livres_1232-2096.jpg"
      :title="reference"
      subtext=""
    />
    <file-list :reference="reference" />
  </div>
</template>

<script>
export default {
  name: "Documentation",

  metaInfo: { title: "Documentation" },

  mounted() {
    this.reference = this.$route.params.id
      .substr(0, this.$route.params.id.length - 4)
      .toUpperCase();
  },

  data: () => ({
    reference: ""
  }),

  methods: {},
  components: {
    HeroAlt: () => import("@/components/mixins/HeroAlt"),
    FileList: () => import("@/components/FileList")
  }
};
</script>
